import React, { useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Head from "../components/head";
import Header from "../components/Header_normal";
import { Box, Button } from "@material-ui/core";
import Footer from "../components/footer";
import HooderButton from "../components/Hooder_button";
import styled from "styled-components";
import WrapTypo from "../components/WrapTypo";
import Grid from "@material-ui/core/Grid";
import Img from "gatsby-image";
import PageTop from "../components/PageTop";

const Campaign = () => {
  const data = useStaticQuery(graphql`
    query {
      laki_hair_canpain2: file(relativePath: { eq: "laki_hair_canpain2.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      product: file(relativePath: { eq: "product@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      productDetail: file(relativePath: { eq: "productDetail.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      vector: file(relativePath: { eq: "vector_media.png" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <>
      <Head />
      <Box width="100%" bgcolor="#ffffff">
        <Header />
        <PageTop />
        <Box margin="auto" textAlign="center" width="90%" my={6}>
          <WrapTypo AmaticSC fw="500" fs="90px" color="#4A2609">
            CAMPAIGN
          </WrapTypo>
        </Box>
        <Box width="50%" margin="auto" mb={5}>
          <Img fluid={data.laki_hair_canpain2.childImageSharp.fluid} />
        </Box>
        <Box margin="auto" width="90%" textAlign="center" my={8} lh="100%">
          <WrapTypo fs="18px" color="#000000">
            ボリュームシャンプーで、ヘアアップ❤️
            <br />
            頭皮マッサージで、フェイスアップ❤️
          </WrapTypo>
        </Box>
        <Box
          margin="auto"
          width={["90%", "90%", "50%", "50%"]}
          mb={5}
          lh="100%"
        >
          <WrapTypo color="#000000">
            シャンプーブロー と 頭皮マッサージ　のセットの3回券のご購入で、
          </WrapTypo>
          <br />
          サテニークエクストラボリュームシャンプー280ml
          <br />
          サテニークエクストラボリュームコンディショナー280ml
          <br />
          <br />
          <WrapTypo color="#000000">
            をプレゼント致します。
            数に限りがあるため、限定10組様のご提供とさせていただきます🙇‍♀️
            <br />
            {/* <span style={{"color:red"}}> */}
            2021年8月20日〜10月31日
            {/* </span> */}
            まで開催しておりますが、売り切れ次第終了となります。
            <br />
            なるべく、早めにお買い求めください！！！
          </WrapTypo>
          <Box my={6} width="90%" margin="auto">
            <ul>
              <li>シャンプーブロー,頭皮マッサージのセット 3回券</li>
              <li>サテニークエクストラボリュームシャンプー280ml</li>
              <li>サテニークエクストラボリュームコンディショナー280ml</li>
            </ul>
            <br />
            　(シャンプーコンディショナー一式3600円相当)
          </Box>
          <Box margin="auto">
            <WrapTypo color="#000000">価格　¥7000(税込)</WrapTypo>
            <WrapTypo color="#000000">期間　2021年8月20日〜10月31日</WrapTypo>
          </Box>
        </Box>
        <Box textAlign="center">
          <WrapTypo color="#000000">
            ご不明な点がございましたら、
            <br />
            事前にお問い合わせください。
            <br />
          </WrapTypo>
        </Box>
        <Box py={6} textAlign="center">
          <Link to="/#access">
            <WrapTypo color="#000000" fw="600" fs="14px">
              お問い合わせはこちら
            </WrapTypo>
          </Link>
        </Box>
        <HooderButton />
        {/* <Box
          className="fv"
          width="90%"
          maxWidth="1200px"
          mx={"auto"}
          py="32px"
          position="relative"
        ></Box> */}
        <Footer />
      </Box>
    </>
  );
};

export default Campaign;
